.dashboard-card {
  border: 1px solid #e2e8f0;
  padding: 24px;
  box-shadow: 0px 7.5px 17.5px 0px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  margin-bottom: 24px;
  width: 100% !important;
}

.dashboard-card-head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
}

.dashboard-card .dashboard-card-body .appointment-list .appointment-item:hover {
  background-color: rgb(247, 242, 242);
  border-radius: 10px;
  transform: translateY(-5px);
}

.header-title h5 {
  margin: 0;
}

.header-dropdown .nav-tog {
  cursor: pointer;
}

.patient-info-profile {
  display: flex;
  align-items: center;
}

.dashboard-card .dashboard-card-body .appointment-list .appointment-item {
  color: #327edf;
  transition: 0.3s ease;
}

.dashboard-card
  .dashboard-card-body
  .appointment-list
  .appointment-item
  .table-avatar1
  img {
  border-radius: 10px;
  width: 40px;
  height: 40px;
  margin-right: 15px;
}

.patient-name-info {
  display: flex;
  flex-direction: column;
}

.patient-name-info span {
  font-size: 12px;
}

.patient-name-info p {
  margin: 0;
  font-size: 15px;
}

.appointment-date-created {
  text-align: left;
}

.appointment-date-created .date-part {
  margin: 0;
  font-size: 14px;
  color: black;
}

.badge {
  background-color: #14b8a6;
  font-size: 11px;
}

.apponiment-actions a {
  font-size: 15px;
  cursor: pointer;
}

.text-success-icon {
  color: var(--bs-success);
  border-radius: 50%;
  width: 25px;
  height: 25px;
  padding: 2px;
  text-align: center;
  border: 1px solid var(--bs-success);
}
.text-success-icon:hover {
  background-color: var(--bs-success);
  color: #fff;
}

.text-danger-icon {
  margin-left: 10px;
  color: var(--bs-danger);
  border-radius: 50%;
  width: 25px;
  height: 25px;
  padding: 2px;
  text-align: center;
  border: 1px solid var(--bs-danger);
}

.text-danger-icon:hover {
  background-color: var(--bs-danger);
  color: #fff;
}

.appointment-list {
  display: flex;
  flex-direction: column;
}

.appointment-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 10px;
  border-bottom: 1px solid #e2e8f0;
}

.appointment-item:last-child {
  border-bottom: none;
}

@media (max-width: 500px) {
  .dashboard-card .dashboard-card-body .appointment-list {
    overflow-x: auto;
  }
  .appointment-list .appointment-item {
    width: 500px;
  }
  .table-avatar img {
    margin-right: 40px;
  }
}
