.appointment-wrap {
  border: 0.5px solid rgb(236, 230, 230);
  border-radius: var(--bs-border-radius);
  margin-bottom: 10px;
  padding: 10px;
  flex-wrap: wrap;
  padding-top: 20px;
  transition: 0.3s ease;
  margin-top: 10px;
}

.appointment-wrap:hover {
  transform: translateY(-5px);
}

.appointment-wrap ul {
  flex-wrap: wrap;
  list-style-type: none;
  padding: 0;
  display: flex;
  justify-content: space-between;
}

.patinet-information {
  display: flex;
  align-items: center;
}

.patinet-information img {
  width: 40px;
  height: 40px;
  border-radius: 10px;
  margin-right: 1rem;
}

/* Style for patient-info */
.patient-info {
  flex: 1;
}

.patient-info p {
  color: var(--bs-primary);
  font-size: 12px;
  margin-bottom: 0.5rem;
}

.patient-info h6 {
  margin-bottom: 0;
}

.patient-info h6 a {
  color: var(--bs-secondary);
  text-decoration: none;
  font-size: 15px;
}

.patient-info h6 a:hover {
  text-decoration: underline;
}

/* Style for appointment-info */
.appointment-info {
  color: var(--bs-secondary);
}

.appointment-info p {
  font-size: 12px;
}

.apponitment-types {
  display: flex;
}

.apponitment-types li {
  background-color: var(--bs-gray-200);
  color: var(--bs-secondary);
  font-size: 1rem;
  padding: 0.25rem 0.5rem;
  margin-top: 10px;
  border-radius: var(--bs-border-radius-sm);
}

/* Style for mail-info-patient */
.mail-info-patient {
  color: var(--bs-secondary);
  font-size: 15px;
}

/* Style for appointment-start */
.appointment-start {
  text-align: right;
}

.start-link {
  border: 1px solid #3eb75e;
  border-radius: 10px;
  padding: 0.5rem 1rem;
}

.start-link:hover {
  background-color: #3eb75e;
  color: var(--bs-light);
  text-decoration: none;
}

/* Appointment Wrap */
.appointment-wrap {
  list-style: none;
  padding: 1rem;
  margin: 0;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #fff;
}

.patinet-information .modal-img {
  border-radius: 10px;
  width: 70px;
  height: 70px;
}

.patient-info p {
  margin: 0;
  font-size: 12px;
  color: rgb(124, 124, 221);
}

.patient-info h6 {
  margin: 0.5rem 0 0;
  font-size: 16px;
  color: var(--bs-primary);
}

.mail-info-patient ul {
  list-style: none;
  padding: 0;
  display: block;
  margin: 0;
  font-size: 14px;
}

.mail-info-patient li {
  margin: 0.25rem 0;
  display: flex;
  align-items: center;
}

.mail-info-patient i {
  margin-right: 0.5rem;
}

/* Appointment Information Section */
.appointment-info {
  margin-bottom: 1rem;
}

.person-info p {
  margin: 0 0 0.5rem;
  font-size: 14px;
  color: #333;
}

.apponitment-types {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  gap: 0.5rem;
}

.apponitment-types li {
  display: flex;
  align-items: center;
  font-size: 14px;
  color: var(--bs-success);
}

.apponitment-types i {
  margin-right: 0.5rem;
}

/* Appointment Action Section */
.appointment-action {
  justify-content: space-between;
  align-items: center;
  margin-top: 1rem;
}

.detail-badge-info {
  display: flex;
  gap: 0.5rem;
}

.badge-modal {
  padding: 0.5rem 1rem;
  font-size: 12px;
  border-radius: 10px;
  border: 1px solid #198754;
  cursor: pointer;
}

.badge-modal:hover {
  color: #fff;
  background-color: #3eb75e;
}

.consult-fees h6 {
  margin: 0;
  font-size: 16px;
  color: #333;
}

/* Modal Line */
.modal-line {
  border-top: 1px solid #ddd;
  margin: 1rem 0;
}

/* Detail Card Bottom Info */
.detail-card-bottom-info {
  list-style: none;
  padding: 0;
  margin: 0;
}

.detail-card-bottom-info li {
  margin-bottom: 1rem;
}

.detail-card-bottom-info h6 {
  margin: 0;
  font-size: 14px;
  color: #333;
}

.detail-card-bottom-info span {
  display: block;
  font-size: 14px;
  color: #666;
}

/* Start Button */
.start-btn {
  text-align: center;
}

.start-btn .btn {
  border: 1px solid #3eb75e;
  padding: 0.5rem 1rem;
  font-size: 14px;
  border-radius: 5px;
  cursor: pointer;
}

.start-btn .btn:hover {
  background-color: #3eb75e;
  color: #fff;
}
