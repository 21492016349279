.rbt-table-wrapper {
  overflow-x: auto;
}

@media (max-width: 576px) {
  .rbt-table-wrapper {
    width: 100%;
    overflow-x: auto;
  }

  .rbt-table {
    min-width: 700px; 
  }
}
