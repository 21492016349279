.appointment-tab-head {
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 1rem;
  background-color: var(--bs-light-bg-subtle);
  border-radius: var(--bs-border-radius-lg);
  box-shadow: var(--bs-box-shadow-sm);
}

.appointment-tabs {
  flex: 1;
}

.nav-pills .nav-link {
  border: 1px solid #3eb75e;
  margin: 0 0.5rem;
  padding: 0.9rem 1rem;
  border-radius: var(--bs-border-radius);
}

.nav-pills .nav-link:hover {
  color: var(--bs-white);
  background-color: #3eb75e;
}

.nav-pills .nav-link:focus {
  color: var(--bs-white);
  background-color: #3eb75e;
}

.nav-pills .nav-link span {
  margin-left: 0.5rem;
  font-weight: bold;
}

.filter-head {
  display: flex;
  align-items: center;
}

.filter-head .position-relative .input-groupicon input {
  height: 40px;
}

.daterange-wraper {
  position: relative;
}

.daterange-wraper .fa-calendar-days {
  position: absolute;
  top: 50%;
  right: 1rem;
  transform: translateY(-50%);
  color: var(--bs-gray-500);
}

.calender-input input {
  padding-right: 2.5rem;
}

.form-sorts {
  position: relative;
  margin-left: 20px;
}

.form-sorts .dropdown-toggle {
  display: flex;
  align-items: center;
  color: var(--bs-body-color);
  text-decoration: none;
}

.form-sorts .dropdown-toggle .fa-filter {
  margin-right: 0.5rem;
}

.filter-dropdown-menu {
  display: none;
  position: absolute;
  right: 0;
  top: 30px;
  width: 365px;
  background-color: var(--bs-white);
  box-shadow: var(--bs-box-shadow-sm);
  border-radius: var(--bs-border-radius);
  z-index: 1000;
}

.form-sorts:hover .filter-dropdown-menu {
  display: block;
}

.filter-set-view {
  padding: 1rem;
}

.filter-set-content {
  margin-bottom: 1rem;
}

.filter-set-content-head a {
  color: var(--bs-body-color);
  text-decoration: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: bold;
  font-size: 20px;
}

.filter-set-content-head a:hover {
  color: var(--bs-primary);
}

.filter-set-contents {
  margin-top: 0.5rem;
}

.filter-set-content ul li {
  list-style: none;
}

.filter-checks {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
}

.filter-checks .checkboxs {
  position: relative;
  display: inline-block;
  cursor: pointer;
  font-size: 15px;
  margin-left: 15px;
}

.filter-checks .checkboxs input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.filter-checks .checkboxs .checkmarks {
  position: absolute;
  top: 0;
  left: 0;
  height: 1.8rem;
  width: 1.8rem;
  background-color: var(--bs-light);
  border: 1px solid var(--bs-gray-300);
  border-radius: 0.25rem;
}

.filter-checks .checkboxs input:checked ~ .checkmarks {
  background-color: var(--bs-primary);
}

.filter-checks .checkboxs .check-title {
  margin-left: 2.5rem;
}

.filter-reset-btns {
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
}

.filter-reset-btns .btn {
  padding: 0.5rem 1rem;
  font-size: 15px;
  border-radius: var(--bs-border-radius);
}

@media (max-width: 768px) {
  .appointment-tab-head {
    flex-direction: column;
    align-items: flex-start;
  }

  .appointment-tabs {
    width: 100%;
    margin-bottom: 1rem;
  }

  .nav-pills .nav-link {
    margin: 0.25rem 0;
    text-align: center;
    width: 100%;
  }

  .filter-head {
    flex-direction: column;
    width: 100%;
  }

  .daterange-wraper {
    width: 100%;
    margin-bottom: 1rem;
  }

  .form-sorts {
    width: 100%;
  }

  .filter-dropdown-menu {
    width: 100%;
  }
}
